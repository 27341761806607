import React, { useCallback, useRef, useMemo } from "react";
import { useDropArea } from "react-use";
import { useApolloClient } from "@apollo/client";

import { Button, useToasts, styled, Text } from "@puzzle/ui";
import { Paperclip } from "@puzzle/icons";

import PDFViewer from "components/common/PDFViewer";
import useFile from "components/common/files/useFile";
import { AccountingRecordActivityType, AssociatedEntity, FileFragment } from "graphql/types";
import Analytics from "lib/analytics";
import useSelf from "components/users/useSelf";
import { SidebarToggle } from "components/common/SidebarToggle";
import {
  SingleBillFragment,
  SingleBillFragmentDoc,
} from "components/dashboard/Accounting/Bills/graphql.generated";
import {
  FullJournalEntryFragment,
  FullJournalEntryFragmentDoc,
} from "components/dashboard/Accounting/ManualJournals/graphql.generated";
import {
  SingleLedgerReconciliationFragment,
  SingleLedgerReconciliationFragmentDoc,
} from "components/dashboard/Accounting/Reconciliation/LedgerReconciliation/graphql.generated";

export const UploadContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  pointerEvents: "none",
  gap: "$2h",
});

export const AttachmentRoot = styled("div", {
  height: "100%",
  alignItems: "center",
  backgroundColor: "#1b1c29",
  position: "relative",
  borderRadius: "$2",
  border: "1px solid transparent",
  transition: "border-color 0.1s ease-in",
  display: "flex",
  justifyContent: "center",
  whiteSpace: "nowrap",
  overflow: "hidden",

  variants: {
    isDropping: {
      true: {
        borderColor: "$purple400",
        [`${UploadContent}`]: {
          [`${Button}`]: {
            pointerEvents: "none",
          },
        },
      },
      false: {
        [`${UploadContent}`]: {
          [`${Button}`]: {
            pointerEvents: "all",
          },
        },
      },
    },
  },
});

const UPLOAD_ICON_SIZE = 21;

type SupportedEntity =
  | SingleBillFragment
  | FullJournalEntryFragment
  | SingleLedgerReconciliationFragment;
type SupportedUploadEntity =
  | AssociatedEntity.Bill
  | AssociatedEntity.ManualJournalEntry
  | AssociatedEntity.LedgerReconciliation;

export const AttachmentSideBar = ({
  entity,
  inMemoryFile,
  setInMemoryFile,
  isEditor,
  toggleSidebar,
  sidebarOpen,
  uploadEntityType,
  text = "Upload file (optional but recommended)",
}: {
  entity?: SupportedEntity;
  uploadEntityType: SupportedUploadEntity;
  inMemoryFile?: File;
  setInMemoryFile: (file?: File) => void;
  sidebarOpen: boolean;
  toggleSidebar?: () => void;
  isEditor: boolean;
  text?: string;
}) => {
  const client = useApolloClient();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const openFilePicker = useCallback(() => hiddenFileInput.current?.click(), []);
  const { self } = useSelf();
  const { toast } = useToasts();

  const getExistingFile = useCallback(() => {
    if (!entity) return undefined;

    if ("documents" in entity) {
      return entity.documents[0]?.file;
    }

    if ("storedFiles" in entity && entity.storedFiles) {
      return entity.storedFiles[0];
    }

    return undefined;
  }, [entity]);

  const existingFile = getExistingFile();

  const getCacheFragment = useCallback(() => {
    if (!entity) return;

    const configMap: Record<
      SupportedUploadEntity,
      {
        id: string;
        fragmentName: string;
        fragment:
          | typeof SingleBillFragmentDoc
          | typeof FullJournalEntryFragmentDoc
          | typeof SingleLedgerReconciliationFragmentDoc;
      }
    > = {
      [AssociatedEntity.Bill]: {
        id: `Bill:${entity?.id}`,
        fragmentName: "singleBill",
        fragment: SingleBillFragmentDoc,
      },
      [AssociatedEntity.ManualJournalEntry]: {
        id: `ManualJournalEntry:${entity?.id}`,
        fragmentName: "fullJournalEntry",
        fragment: FullJournalEntryFragmentDoc,
      },
      [AssociatedEntity.LedgerReconciliation]: {
        id: `LedgerReconciliation:${entity?.id}`,
        fragmentName: "singleLedgerReconciliation",
        fragment: SingleLedgerReconciliationFragmentDoc,
      },
    };

    const config = configMap[uploadEntityType];

    return {
      config,
      fragment: client.readFragment<typeof entity>(config),
    };
  }, [uploadEntityType, client, entity]);

  const updateFileCache = useCallback(
    (
      file: FileFragment,
      activityType:
        | AccountingRecordActivityType.FileUploaded
        | AccountingRecordActivityType.FileDeleted
    ) => {
      if (!entity) return;

      const cache = getCacheFragment();

      if (!cache) return;

      if (cache.fragment && self) {
        const createdAt = new Date().toISOString();
        const fileFieldName = "storedFiles" in cache.fragment ? "storedFiles" : "documents";
        const newFiles = "storedFiles" in cache.fragment ? [{ ...file }] : [{ file }];
        const activity =
          "activity" in cache.fragment
            ? {
                activity: [
                  ...cache.fragment.activity,
                  {
                    __typename:
                      activityType === AccountingRecordActivityType.FileUploaded
                        ? "AccountingRecordFileUploaded"
                        : "AccountingRecordFileDeleted",
                    id: createdAt,
                    type: activityType,
                    createdAt,
                    file,
                    createdByUser: {
                      id: self.id,
                      name: self.name,
                    },
                  },
                ],
              }
            : undefined;

        client.writeFragment({
          ...cache.config,
          data: {
            ...cache.fragment,
            ...activity,
            [fileFieldName]:
              activityType === AccountingRecordActivityType.FileUploaded ? newFiles : [],
          },
        });
      }
    },
    [client, entity, self, getCacheFragment]
  );

  const { isUploading, onFiles, deleteFile } = useFile({
    entityId: entity?.id,
    entityType: uploadEntityType,
    onFileDeleted: ({ deleteFile }) => {
      Analytics.accountingRecordAttachmentDeleted({ fileId: deleteFile.fileId, uploadEntityType });
    },
    onUploadComplete: ([file]) => {
      if (!entity) return;
      updateFileCache(file, AccountingRecordActivityType.FileUploaded);
      toast({
        status: "success",
        message: "Attachment saved",
      });

      Analytics.accountingRecordAttachmentUploaded({
        id: entity.id,
        contentType: file.contentType,
        fileName: file.filename ?? "",
        fileSize: file.size,
        uploadEntityType: uploadEntityType,
      });
    },
    file: existingFile,
    skipOnFiles: !entity,
  });

  const onDelete = useCallback(() => {
    if (inMemoryFile) {
      setInMemoryFile(undefined);
    }

    if (existingFile) {
      deleteFile();
      updateFileCache(existingFile, AccountingRecordActivityType.FileDeleted);
    }
  }, [deleteFile, inMemoryFile, existingFile, updateFileCache, setInMemoryFile]);

  const handleFiles = useCallback(
    (files: File[] | FileList) => {
      if (!isEditor) return;
      if (files[0] && files[0].type !== "application/pdf") {
        toast({ status: "warning", message: "Please upload a PDF" });
        return;
      }

      // Entity doesn't exist yet, preview in memory and upload when they post
      if (!entity) {
        setInMemoryFile(files[0]);
        return;
      }

      // Entity already exists, upload the file
      onFiles(files);
    },
    [entity, onFiles, setInMemoryFile, isEditor, toast]
  );

  const previewUrl = useMemo(
    () => (inMemoryFile ? URL.createObjectURL(inMemoryFile) : existingFile?.downloadInfo.signedUrl),
    [existingFile?.downloadInfo.signedUrl, inMemoryFile]
  );

  const [dropAreaProps, { over }] = useDropArea({
    onFiles: handleFiles,
  });

  return (
    <>
      {previewUrl && (
        <PDFViewer
          url={previewUrl}
          onToggle={toggleSidebar}
          onDelete={isEditor ? onDelete : undefined}
        />
      )}
      {!previewUrl && (
        <AttachmentRoot {...dropAreaProps} isDropping={over && isEditor}>
          {toggleSidebar && (
            <SidebarToggle
              open={sidebarOpen}
              onClick={() => toggleSidebar()}
              css={{
                position: "absolute",
                top: "$3",
                right: "$3",
              }}
            />
          )}

          <UploadContent>
            <Paperclip size={UPLOAD_ICON_SIZE} color="#95949B" />
            <Text size="headingS" weight="bold" color="gray300">
              {text}
            </Text>
            <Text color="gray500" size="bodyS">
              Drag and drop files here, or
            </Text>
            <Button
              variant="secondary"
              size="compact"
              onClick={openFilePicker}
              disabled={!isEditor}
              loading={isUploading}
              css={{ marginTop: "-$1" }}
            >
              Browse my computer
            </Button>
          </UploadContent>
        </AttachmentRoot>
      )}
      <input
        type="file"
        ref={hiddenFileInput}
        accept=".pdf"
        style={{ display: "none" }}
        onChange={(e) => {
          if (!e.target.files) return;
          handleFiles(e.target.files);
        }}
      />
    </>
  );
};
