import React, { useEffect, useRef, useState } from "react";
import { styled, Menu } from "@puzzle/ui";
import { useAsync } from "react-use";

import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import { PDFViewer as Viewer } from "pdfjs-dist/web/pdf_viewer.mjs";
import "pdfjs-dist/web/pdf_viewer.css";

import * as Toolbar from "@radix-ui/react-toolbar";
import { MinimizeLeft, Ellipse, ZoomIn, ZoomOut } from "@puzzle/icons";

const PDFContainer = styled("div", {
  position: "absolute",
  overflow: "auto",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
});

const ToolbarRoot = styled(Toolbar.Root, {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  gap: "$1",
  padding: "$0h",
  right: 16,
  top: 16,
  background: "rgba(255, 255, 255, 0.5)",
  color: "$gray600",
  backdropFilter: "blur(5px)",
  borderRadius: "$1",

  transition: "opacity 0.15s ease-out",

  opacity: 0,
  '&[data-state="open"]': {
    opacity: 1,
  },

  svg: {
    width: 18,
    height: 18,
  },
});

const ToolbarButton = styled(Toolbar.Button, {
  unstyled: true,
  cursor: "pointer",
  padding: "$1 !important",
  borderRadius: "$ellipse",
  transition: "background 0.15s ease-out",
  lineHeight: 0,
  color: "inherit",

  "&:hover": {
    backgroundColor: "$gray100",
  },
});

const ToolbarSeparator = styled(Toolbar.Separator, {
  width: 24,
  height: 24,
});

const Document = styled("div", {
  position: "relative",
  height: "100%",
  width: "100%",
  iframe: {
    border: "none",
  },

  [`&:hover ${ToolbarRoot}`]: {
    opacity: 1,
  },

  ".page": {
    margin: "0 auto",
    position: "relative",
    ".textLayer": {
      position: "absolute",
      top: 0,
      left: 0,
    },
  },
});

type Props = {
  url: string;
  onReplace?: () => void;
  onDelete?: () => void;
  onToggle?: () => void;
};

const PDFViewer = ({ url, onReplace, onDelete, onToggle }: Props) => {
  const modules = useAsync(async () => {
    const pdfjs = await import("pdfjs-dist");
    const pdfjsviewer = await import("pdfjs-dist/web/pdf_viewer.mjs");

    return { pdfjs, pdfjsviewer };
  }, []);

  const PDFJS = modules.value?.pdfjs;
  const PDFJSViewer = modules.value?.pdfjsviewer;

  if (PDFJS) {
    PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.mjs`;
  }

  const containerRef = useRef<HTMLDivElement>(null);
  const viewerRef = useRef<HTMLDivElement>(null);
  const pdfDocumentRef = useRef<PDFDocumentProxy>();
  const pdfViewerRef = useRef<Viewer>();
  const [documentReady, setDocumentReady] = useState(false);
  const needsRefresh = useRef<boolean>(true);

  useEffect(() => {
    if (!url || !PDFJS) {
      return;
    }

    setDocumentReady(false);

    PDFJS.getDocument(url).promise.then((document) => {
      pdfDocumentRef.current = document;
      setDocumentReady(true);
    });
  }, [PDFJS, url]);

  const [scale, setScale] = useState(1);

  useEffect(() => {
    const container = containerRef.current;
    const viewer = viewerRef.current ?? undefined;
    const pdfDocument = pdfDocumentRef.current;

    if (
      !pdfDocument ||
      !documentReady ||
      !container ||
      !PDFJS ||
      !PDFJSViewer ||
      !needsRefresh.current
    ) {
      return;
    }

    const render = async () => {
      const eventBus = new PDFJSViewer.EventBus();
      const linkService = new PDFJSViewer.PDFLinkService({ eventBus });
      const findController = new PDFJSViewer.PDFFindController({ eventBus, linkService });

      const pdfViewer = new PDFJSViewer.PDFViewer({
        container,
        viewer,
        eventBus,
        linkService,
        findController,
        textLayerMode: 1,
        l10n: new PDFJSViewer.GenericL10n("en-US"),
      });

      pdfViewerRef.current = pdfViewer;
      needsRefresh.current = false;

      pdfViewer.setDocument(pdfDocument);
      linkService.setDocument(pdfDocument, null);

      linkService.setViewer(pdfViewer);
      eventBus.on("pagesinit", function () {
        pdfViewer.currentScaleValue = "auto";
        setScale(pdfViewer._currentScale);
      });
    };

    render();
  }, [PDFJS, PDFJSViewer, documentReady]);

  useEffect(() => {
    if (pdfViewerRef.current) {
      pdfViewerRef.current.currentScaleValue = scale.toString();
    }
  }, [scale]);

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Document>
      <PDFContainer ref={containerRef}>
        <div ref={viewerRef} />
      </PDFContainer>

      <ToolbarRoot css={{ opacity: menuOpen ? 1 : undefined }}>
        {onToggle && (
          <ToolbarButton onClick={onToggle}>
            <MinimizeLeft />
          </ToolbarButton>
        )}

        <Menu
          modal={false}
          open={menuOpen}
          onOpenChange={setMenuOpen}
          trigger={
            <ToolbarButton>
              <Ellipse fill="currentColor" rotate={90} />
            </ToolbarButton>
          }
        >
          <Menu.Group>
            {onReplace && (
              <Menu.Item
                onSelect={() => {
                  onReplace();
                  needsRefresh.current = true;
                }}
              >
                Replace
              </Menu.Item>
            )}
            <Menu.Item onClick={() => window.open(url)}>Download</Menu.Item>
            {onDelete && (
              <Menu.Item
                onSelect={() => {
                  onDelete();
                  needsRefresh.current = true;
                }}
              >
                Delete
              </Menu.Item>
            )}
          </Menu.Group>
        </Menu>

        <ToolbarSeparator />

        <ToolbarButton onClick={() => setScale((s) => s + 0.05)}>
          <ZoomIn />
        </ToolbarButton>

        <ToolbarButton onClick={() => setScale((s) => s - 0.05)}>
          <ZoomOut />
        </ToolbarButton>
      </ToolbarRoot>
    </Document>
  );
};

export default PDFViewer;
