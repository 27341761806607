import React from "react";
import Icon, { IconProps } from "./Icon";
import { Colors } from "./colors";

export default function Ellipse({
  fill = Colors.BaseGrey,
  viewBox = "0 0 16 16",
  ...iconProps
}: IconProps) {
  return (
    <Icon viewBox={viewBox} {...iconProps}>
      <path
        fill={fill}
        d="M9.3125 8C9.3125 7.64453 9.17578 7.34375 8.92969 7.07031C8.65625 6.82422 8.35547 6.6875 8 6.6875C7.61719 6.6875 7.31641 6.82422 7.07031 7.07031C6.79688 7.34375 6.6875 7.64453 6.6875 8C6.6875 8.38281 6.79688 8.68359 7.07031 8.92969C7.31641 9.20312 7.61719 9.3125 8 9.3125C8.35547 9.3125 8.65625 9.20312 8.92969 8.92969C9.17578 8.68359 9.3125 8.38281 9.3125 8ZM12.5938 6.6875C12.9492 6.6875 13.25 6.82422 13.5234 7.07031C13.7695 7.34375 13.9062 7.64453 13.9062 8C13.9062 8.38281 13.7695 8.68359 13.5234 8.92969C13.25 9.20312 12.9492 9.3125 12.5938 9.3125C12.2109 9.3125 11.9102 9.20312 11.6641 8.92969C11.3906 8.68359 11.2812 8.38281 11.2812 8C11.2812 7.64453 11.3906 7.34375 11.6641 7.07031C11.9102 6.82422 12.2109 6.6875 12.5938 6.6875ZM3.40625 6.6875C3.76172 6.6875 4.0625 6.82422 4.33594 7.07031C4.58203 7.34375 4.71875 7.64453 4.71875 8C4.71875 8.38281 4.58203 8.68359 4.33594 8.92969C4.0625 9.20312 3.76172 9.3125 3.40625 9.3125C3.02344 9.3125 2.72266 9.20312 2.47656 8.92969C2.20312 8.68359 2.09375 8.38281 2.09375 8C2.09375 7.64453 2.20312 7.34375 2.47656 7.07031C2.72266 6.82422 3.02344 6.6875 3.40625 6.6875Z"
      />
    </Icon>
  );
}
