import React from "react";
import Icon, { IconProps } from "./Icon";

export function ZoomIn({
  viewBox = "0 0 18 18",
  color = "currentColor",
  width = 18,
  height = 18,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} fill="none" viewBox={viewBox}>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14 8.5a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0v0z"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.5 8.5h4M8.5 6.5v4"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13 13l2.5 2.5"
      ></path>
    </Icon>
  );
}

export function ZoomOut({
  viewBox = "0 0 18 18",
  color = "currentColor",
  width = 18,
  height = 18,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} fill="none" viewBox={viewBox}>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14 8.5a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0v0z"
      ></path>
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" d="M6.5 8.5h4"></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13 13l2.5 2.5"
      ></path>
    </Icon>
  );
}
